<template>
  <div class="skills">
    <h1>This place is empty now :)</h1>
  </div>
</template>

<script>
export default {
  name: "Skills",
};
</script>